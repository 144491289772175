import useStore from './useStore';

export const translations = {
  nl: {
    closeA11y: 'Sluit toegankelijkheidsmenu',
    increaseFontSize: 'Vergroot tekstgrootte',
    decreaseFontSize: 'Verklein tekstgrootte',
    fontSize: 'Tekstgrootte',
    accessibility: 'Toegankelijkheid',
    a11yContent:
      'Lukt het niet online? Getrainde vrijwilligers helpen u op weg met al uw vragen over uw computer, laptop, mobiel én tablet. Bel de DigiHulplijn gratis van maandag tot en met vrijdag tussen 09:00 en 17:00 uur.',
    a11yTitle: 'Digitale hulp nodig?',
    a11yCta: 'Alles over toegankelijkheid',
    author: 'Foto',
    comingSoon: 'Coming soon',
    patience:
      'Nog even geduld… Op 11 april gaan we live met explorebreda.com en kun je hier terecht voor alle informatie en inspiratie over Breda!',
    untilThen: 'Tot die tijd:',
    visitWebsite: 'Bezoek welkominbreda.nl ',
    clearFilters: 'Wis filters',
    expand: 'Uitklappen',
    collapse: 'Inklappen',
    call: 'Bel',
    readMore: 'Lees meer',
    emptyListTitle: 'Je lijst is nog leeg!',
    emptyListText:
      'Waar wacht je nog op? Hoogste tijd om deze mooie stad te gaan ontdekken. Verzamel hier jouw persoonlijke programma voor een dagje, weekend of weekje Breda.',
    storeEmptyListTitle: 'Je lijst is nog leeg!',
    storeEmptyListText:
      'Waar wacht je nog op? Hoogste tijd om deze mooie stad te gaan ontdekken. Verzamel hier jouw persoonlijke programma voor een dagje, weekend of weekje Breda.',
    emptyMessageButton: 'Start met ontdekken',
    storeBookmarksResult: 'Jouw volgende ontdekking',
    storeBookmarksResults: 'Jouw volgende {count} ontdekkingen',
    qrResultsShare:
      'Scan de QR code en stuur de selectie hieronder direct door naar je telefoon.',
    showAll: 'Toon alles',
    viewMore: 'Bekijk meer',
    seconds: 'seconden',
    storeIdleTitle: 'Hallo daar! Ben je nog aan het ontdekken?',
    storeIdleYes: 'Ja, ik ben er nog!',
    storeIdleReset: 'Start opnieuw',
    storeA11yTitle: 'Heeft u vragen?',
    storeA11yText:
      'Als u een vraag heeft over het gebruik van dit scherm of over andere zaken dan kunt u altijd één van onze medewerkers om hulp vragen. Zij helpen u graag verder!',
    back: 'Terug',
    downloadRoutePdf: 'Download route (PDF)',
    downloadRouteGpx: 'Download route (GPX)',
    showDates: 'Toon meer data',
    result: 'Resultaat gevonden',
    results: 'Resultaten gevonden',
    about: 'Over',
    start: 'Start',
    end: 'Einde',
    locations: 'Locaties',
    share: 'Delen',
    urlCopied: 'URL gekopieerd',
    MONDAY: 'Maandag',
    TUESDAY: 'Dinsdag',
    WEDNESDAY: 'Woensdag',
    THURSDAY: 'Donderdag',
    FRIDAY: 'Vrijdag',
    SATURDAY: 'Zaterdag',
    SUNDAY: 'Zondag',
    closed: 'Gesloten',
    each: 'Elke',
    hour: 'Uur',
    exception: 'Uitzondering(en)',
    allDay: 'Hele dag',
    allDayOpen: 'De gehele dag open',
    openingHours: 'Openingstijden',
    information: 'Informatie',
    when: 'Wanneer',
    price: 'Prijzen',
    noBookmarks: 'Je hebt nog geen items opslagen',
    adjustA11y: 'Pas toegankelijkheid aan',
    a11y: 'Toegankelijkheid',
    sharePage: 'Deel deze pagina',
    viewLocation: 'Bekijk locatie',
    chooseDate: 'Kies een datum',
    filter: 'Filteren',
    showResults: 'Toon resultaten',
    search: 'Zoeken',
    searchResults: 'zoekresultaten',
    searchResult: 'zoekresultaat',
    searchLabel: 'Typ hier wat je zoekt...',
    clearSearchQuery: 'Wis zoekopdracht',
    emptySearchQuery: 'Geen zoekopdracht ingevoerd',
    noSearchResultsTitle: 'Geen zoekresultaten gevonden',
    noSearchResultsText:
      'Probeer uw zoekopdracht of filter input aan te passen of probeer het later nog eens.',
    searchDescription:
      'Op zoek naar iets specifieks in Breda? Typ hieronder wat je zoekt.',
    mostSearched: 'Meest gezocht',
    contentType: 'Content type',
    events: 'Evenementen',
    routes: 'Routes',
    blog: 'Artikelen',
    content: "Pagina's",
    loadingMap: 'Kaart wordt geladen...',
    loadingLocations: 'Locaties worden geladen...',
    myList: 'Mijn lijst',
    eventInformation: 'Informatie over het evenement',
    eventName: 'Naam van het evenement',
    eventStart: 'Startdatum & tijd',
    eventEnd: 'Einddatum & tijd',
    description: 'Omschrijving',
    street: 'Straat',
    streetNumber: 'Huisnummer',
    streetNumberSuffix: 'Toevoeging',
    zipcode: 'Postcode',
    city: 'Plaats',
    country: 'Land',
    locationName: 'Locatienaam',
    website: 'Website',
    email: 'E-mailadres',
    phone: 'Telefoonnummer',
    name: 'Naam',
    comments: 'Overige opmerkingen',
    allLocations: 'Alle locaties',
    myLocations: 'Mijn locaties',
    nothingFoundTitle: 'Geen items gevonden',
    nothingFoundText: 'Probeer het later nog eens.',
    nothingFoundTextFilter:
      'Pas uw filter input aan of probeer het later nog eens.',
    sharedTitle: 'Explore-Breda',
    sharedText: 'Bekijk deze pagina op Explore-Breda',
    shareMyList: 'Deel jouw lijst',
    openMenu: 'Open menu',
    closeMenu: 'Sluit menu',
    main: 'Menu',
    list: 'Mijn lijst',
    map: 'Kaart',
    dateAndTime: 'Datum en tijd',
    dateAndTimeFieldsInformation:
      'Einddatum & tijd is een verplicht veld. Heeft het evenement geen einddatum, vul dan dezelfde datum in als de startdatum & tijd.',
    eventLocationTitle: 'Waar vindt het evenement plaats?',
    contactInformation: 'contactinformatie',
    addImage: 'Afbeelding toevoegen',
    yourDetails: 'Jouw gegevens',
    additionalDetails: 'Aanvullende gegevens',
    submitEvent: 'Evenement insturen',
    invalidEmail: 'Ongeldig e-mailadres',
    inputRequired: 'Dit veld is verplicht',
    somethingWentWrong: 'Er is iets misgegaan',
    mustBeNumber: 'Dit veld moet een nummer zijn',
    mustBePositiveNumber: 'Dit veld moet een positief nummer zijn',
    noFileSelected: 'Geen bestand geselecteerd',
    fileToLarge: 'Het bestand is te groot, probeer het bestand te verkleinen',
    bookmarkAdd: 'Opslaan in favorieten',
    bookmarkRemove: 'Verwijderen uit favorieten',
    until: 't/m',
    contactDetails: 'Contactgegevens',
    storeExploreLabel: 'Wat wil jij ontdekken vandaag?',
    storeExploreSubmit: 'Start met ontdekken',
    storeShareQr:
      'Scan de QR code en stuur deze pagina direct door naar je telefoon voor meer informatie.',
    storeBookmarkAdd: "Voeg toe aan 'Mijn lijst'",
    storeBookmarkRemove: "Verwijder uit 'Mijn lijst'",
    storeExploreResultsTitle: '{count} x {subject} ontdekken',
    storeExploreResultsEmptyTitle: 'Geen resultaten gevonden',
    storeExploreResultsEmptyText: 'Probeer het nog eens met andere keuzes.',
    storeExploreWarning:
      'Selecteer hierboven de onderwerpen die je interesseren',
    captchaRequired: 'Recaptcha is verplicht om het formulier te versturen',
    storeErrorTitle: 'Oops, er is iets misgegaan',
    storeErrorSubtitle:
      'Deze pagina bestaat niet of kan momenteel niet opgehaald worden.',
    highlightedBannerSectionTitle: 'Ontdek meer evenementen zoals dit in Breda',
    highlightedBannerSectionText:
      'Verken de diverse evenementen in Breda en geniet van alles wat de stad te bieden heeft!',
    toastMessageMarked: 'Toegevoegd aan jouw lijst',
    toastMessageUnmarked: 'Verwijderd uit jouw lijst',
  },
  en: {
    closeA11y: 'Close accessibility menu',
    increaseFontSize: 'Increase font size',
    decreaseFontSize: 'Decrease font size',
    fontSize: 'Font size',
    accessibility: 'Accessibility',
    a11yContent:
      "Can't figure it out online? Trained volunteers will help you with all your questions about your computer, laptop, mobile and tablet. Call the DigiHulplijn for free from Monday to Friday between 09:00 and 17:00.",
    a11yTitle: 'Digital help needed?',
    a11yCta: 'More about accessibility',
    storeErrorTitle: 'Oops, something went wrong',
    storeErrorSubtitle:
      'This page does not exist or cannot be retrieved at the moment.',
    captchaRequired: 'Recaptcha is required to submit the form',
    storeExploreWarning: 'Select the topics that interest you above',
    author: 'Photo',
    comingSoon: 'Coming soon',
    visitWebsite: 'Visit welkominbreda.nl',
    patience:
      'Just a little more patience.. On April 11th we will go live with the website of Explore Breda and you can find all information and inspiration about Breda here. Until then you can still visit welkominbreda.nl for this. Hopefully we will see you back soon!',
    untilThen: 'Until then:',
    clearFilters: 'Clear filters',
    expand: 'Expand',
    collapse: 'Collapse',
    call: 'Call',
    readMore: 'Read more',
    emptyListTitle: 'Your list is still empty!',
    emptyListText:
      'What are you waiting for? It is high time to discover this beautiful city. Collect your personal program for a day, weekend or week in Breda here.',
    storeEmptyListTitle: 'Your list is still empty!',
    storeEmptyListText:
      'What are you waiting for? It is high time to discover this beautiful city. Collect your personal program for a day, weekend or week in Breda here.',
    emptyMessageButton: 'Start discovering',
    storeBookmarksResult: 'Your next discovery',
    storeBookmarksResults: 'Your next {count} discoveries',
    qrResultsShare:
      'Scan the QR code and send the selection below directly to your phone.',
    showAll: 'Show all',
    viewMore: 'View more',
    seconds: 'seconds',
    storeIdleTitle: 'Hello there! Are you still exploring?',
    storeIdleYes: 'Yes, I am still here!',
    storeIdleReset: 'Start over',
    storeA11yTitle: 'Do you have questions?',
    storeA11yText:
      'If you have a question about using this screen or other matters, you can always ask one of our employees for help. They are happy to help you further!',
    back: 'Back',
    downloadRoutePdf: 'Download route (PDF)',
    downloadRouteGpx: 'Download route (GPX)',
    showDates: 'Show more dates',
    result: 'Item found',
    results: 'Items found',
    about: 'About',
    nothingFoundTextFilter:
      'Try changing your filter input or try again later.',
    loadingMap: 'Loading map...',
    loadingLocations: 'Loading locations...',
    viewLocation: 'View location',
    start: 'Start',
    end: 'End',
    locations: 'Locations',
    share: 'Share',
    urlCopied: 'Copied URL',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    closed: 'Closed',
    each: 'Each',
    hour: 'Hour',
    exception: 'Exception(s)',
    allDay: 'Entire day',
    allDayOpen: 'All day open',
    openingHours: 'Opening hours',
    information: 'Information',
    when: 'When',
    price: 'Prices',
    noBookmarks: 'You have not saved any items yet',
    adjustA11y: 'Adjust accessibility',
    a11y: 'Accessibility',
    sharePage: 'Share this page',
    chooseDate: 'Choose a date',
    filter: 'Filter',
    showResults: 'Show results',
    search: 'Search',
    searchLabel: 'Type here what you are looking for...',
    searchResults: 'search results',
    searchResult: 'search result',
    clearSearchQuery: 'Clear search query',
    emptySearchQuery: 'No search query entered',
    noSearchResultsTitle: 'No search results found',
    noSearchResultsText:
      'Try changing your query or filter input or try again later.',
    searchDescription:
      'Looking for something specific in Breda? Type below what you are looking for.',
    mostSearched: 'Most searched',
    contentType: 'Content type',
    events: 'Events',
    routes: 'Routes',
    blog: 'Articles',
    content: 'Pages',
    myList: 'My list',
    nothingFoundTitle: 'No items found',
    nothingFoundText: 'Try again later.',
    eventInformation: 'Information about the event',
    eventName: 'Name of the event',
    eventStart: 'Start date & time',
    eventEnd: 'End date & time',
    description: 'Description',
    street: 'Street',
    streetNumber: 'House number',
    streetNumberSuffix: 'Addition',
    zipcode: 'Zipcode',
    city: 'City',
    country: 'Country',
    locationName: 'Location name',
    website: 'Website',
    email: 'Email',
    phone: 'Phone',
    name: 'Name',
    comments: 'Other comments',
    allLocations: 'All locations',
    myLocations: 'My locations',
    sharedTitle: 'Explore-Breda',
    sharedText: 'View this page on Explore-Breda',
    shareMyList: 'Share your list',
    openMenu: 'Open menu',
    closeMenu: 'Close menu',
    main: 'Menu',
    list: 'My list',
    map: 'Map',
    dateAndTime: 'Date and time',
    dateAndTimeFieldsInformation:
      'End date & time is a required field. If the event has no end date, fill in the same date as the start date & time.',
    eventLocationTitle: 'Where does the event take place?',
    contactInformation: 'Contact information',
    addImage: 'Add image',
    yourDetails: 'Your details',
    additionalDetails: 'Additional details',
    submitEvent: 'Submit event',
    invalidEmail: 'Invalid email address',
    inputRequired: 'This field is required',
    somethingWentWrong: 'Something went wrong',
    mustBeNumber: 'This field must be a number',
    mustBePositiveNumber: 'This field must be a positive number',
    noFileSelected: 'No file selected',
    fileToLarge: 'The file is too large, try to reduce the file',
    bookmarkAdd: 'Add to favorites',
    bookmarkRemove: 'Remove from favorites',
    until: 'until',
    contactDetails: 'Contact details',
    storeExploreLabel: 'What do you want to explore today?',
    storeExploreSubmit: 'Start exploring',
    storeShareQr:
      'Scan the QR code and send this page directly to your phone for more information.',
    storeBookmarkAdd: "Add to 'My list'",
    storeBookmarkRemove: "Remove from 'My list'",
    storeExploreResultsTitle: 'discover {subject} x {count}',
    storeExploreResultsEmptyTitle: 'No results found',
    storeExploreResultsEmptyText: 'Try again with different choices.',
    highlightedBannerSectionTitle: 'Find More Events Like This in Breda',
    highlightedBannerSectionText:
      'Explore the diverse events in Breda and enjoy everything the city has to offer!',
    toastMessageMarked: 'Added to your list',
    toastMessageUnmarked: 'Removed from your list',
  },
} as const;

export default function useTranslations() {
  const {
    store: { locale },
  } = useStore();

  return translations[locale];
}
